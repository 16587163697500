import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import DialogTitle from '@material-ui/core/DialogTitle'
import { lostReceiptSchema } from '../../utils/Schemes'
import { useFormik } from 'formik'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { LostReceipt } from '../../model/LostReceipt'

type LostReceiptDialog = {
	onSubmit: (lostReceipt: LostReceipt) => void
	initialState?: LostReceipt
}

const defaultState = { description: '', reason: '' }

export function LostReceiptDialog({ onSubmit, initialState }: LostReceiptDialog) {
	const [open, setOpen] = React.useState(false)

	const { values, touched, errors, handleChange, handleBlur, resetForm } = useFormik({
		initialValues: initialState || defaultState,
		onSubmit: (values) => console.log(values),
		validationSchema: lostReceiptSchema,
		enableReinitialize: true,
	})

	function clickedSave() {
		onSubmit(values)
		setOpen(false)
		resetForm()
	}

	return (
		<>
			{renderButton(!!initialState, () => setOpen(true))}
			<Dialog open={open}>
				<DialogTitle id="form-dialog-title">Mistet bilag</DialogTitle>
				<DialogContent>
					<TextField
						id="description"
						label="Udgiftsbeskrivelse"
						value={values.description}
						error={!!errors.description && touched.description}
						helperText={errors.description && touched.description ? errors.description : ' '}
						onChange={handleChange}
						onBlur={handleBlur}
						required
						fullWidth
					/>
					<TextField
						id="reason"
						label="Årsag til manglende bilag"
						value={values.reason}
						error={!!errors.reason && touched.reason}
						helperText={errors.reason && touched.reason ? errors.reason : ' '}
						onChange={handleChange}
						onBlur={handleBlur}
						required
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)}>Annullér</Button>
					<Button
						id={'saveLostReceipt'}
						color="primary"
						onClick={() => clickedSave()}
						disabled={!lostReceiptSchema.isValidSync(values)}
					>
						Gem
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

function renderButton(initialState: boolean, onClick: () => void) {
	if (initialState)
		return (
			<Button id={'editLostReceipt'} size="small" onClick={onClick} color="primary">
				Rediger information
			</Button>
		)

	return (
		<LostReceiptButton id={'lostReceiptButton'} onClick={onClick} variant="outlined" color="primary" size="small">
			<LostReceiptTypography>Mistet Bilag</LostReceiptTypography>
		</LostReceiptButton>
	)
}

const LostReceiptButton = styled(Button)`
	margin-bottom: 15px;
	padding: 10px;
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 1;
	margin-right: 15px;
	border-radius: 5px;
`
const LostReceiptTypography = styled(Typography)`
	font-family: 'Noto Sans', sans-serif;
	font-weight: 600;
	color: #ed7303;
`
