import React, { useEffect } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import moment from 'moment'
import 'moment/locale/da'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import { useFormik } from 'formik'
import { ReceiptCard } from './ReceiptCard'
import { minimumDate } from './ExpenseUtils'
import { DeleteButton } from './DeleteButton'
import { LostReceiptCard } from './LostReceiptCard'
import { expensePurposes, requiresParticipantList } from '../../utils/Purposes'
import { expenseSchema } from '../../utils/Schemes'
import { validateExpense } from '../../utils/Validators'
import { Expense } from '../../model/Expense'
import { LostReceipt } from '../../model/LostReceipt'
import styled from 'styled-components'
import { DetailsPanel, Header, HeaderContainer } from '../CommonStyledComponents'
import { hideCommaNoNumberAfter, toComma } from '../../utils/NumberConverter'

const placeHolder = (amount?: number | string) => {
	if (amount === '' || amount === undefined) return 'Beløb'

	return toComma(amount) + ' kr'
}

const amountRegex = /^\d+(,\d{0,2})?$/

type ExpenseProps = {
	id: number
	onChange: (id: number, expense: Expense) => void
	onDelete: (id: number) => void
	state: Expense
	expanded: boolean
	toggleExpanded: (id: number) => void
}

export function ExpenseInput({ id, onChange, onDelete, state, expanded, toggleExpanded }: ExpenseProps) {
	const { values, touched, errors, handleChange, handleBlur, setFieldValue } = useFormik<Expense>({
		initialValues: state,
		validationSchema: expenseSchema,
		enableReinitialize: true,
		onSubmit: () => {},
	})

	useEffect(() => onChange(id, values), [values])

	const setExpanded = () => toggleExpanded(id)

	function lostReceiptChanged(newState: LostReceipt) {
		onChange(id, { ...state, lostReceipt: newState, lost: true })
	}

	const submit = () => toggleExpanded(id)
	const deleteExpense = () => onDelete(id)

	const validAndClosed = !expanded && validateExpense(values)

	function handleAmountChange({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
		if (value === '') setFieldValue('amount', '')
		else if (amountRegex.test(value.replace('.', ','))) setFieldValue('amount', value.replace(',', '.'))
	}

	return (
		<ExpansionPanel expanded={expanded}>
			<PanelSummary validAndClosed={validAndClosed} onClick={setExpanded} expandIcon={<ExpandMoreIcon />}>
				<HeaderContainer>
					<Header bold>Udgift #{id}</Header>
				</HeaderContainer>
				<HeaderContainer>
					<Header>{moment(values.date).format('D. MMMM')}</Header>
				</HeaderContainer>
				<HeaderContainer>
					<Header>{placeHolder(hideCommaNoNumberAfter(values.amount))}</Header>
				</HeaderContainer>
			</PanelSummary>
			<DetailsPanel>
				<Grid container spacing={3}>
					<CenteringGrid item xs={12} sm={6}>
						<CenteringContainer>
							{state.lost ? (
								<LostReceiptCard
									receiptName={'Udgift #' + id}
									lostReceipt={state.lostReceipt}
									onSave={(state) => lostReceiptChanged(state)}
								/>
							) : (
								<ReceiptCard file={state.file} receiptName={'Udgift #' + id} />
							)}
						</CenteringContainer>
					</CenteringGrid>
					<CenteringGrid item xs={12} sm={6}>
						<KeyboardDatePicker
							margin="normal"
							id="date"
							name="date"
							label="Udgiftsdato"
							value={values.date}
							onChange={(v) => setFieldValue('date', v)}
							onBlur={handleBlur}
							minDate={minimumDate()}
							maxDate={Date.now()}
							format={'DD-MM-YYYY'}
							fullWidth
						/>
						<FormHelperText> </FormHelperText>
						<FormControl fullWidth margin="normal" required>
							<InputLabel id="purpose" htmlFor="purpose">
								Formål
							</InputLabel>
							<Select
								fullWidth
								value={values.purpose}
								onChange={handleChange}
								id="purpose"
								onBlur={handleBlur}
								inputProps={{ id: 'purpose', name: 'purpose' }}
								error={!!errors.purpose && touched.purpose}
							>
								<MenuItem value="">
									<em>Angiv formål</em>
								</MenuItem>
								{expensePurposes.map((p) => (
									<MenuItem key={p.id} value={p.id}>
										{p.name}
									</MenuItem>
								))}
							</Select>
							{errors.purpose && touched.purpose ? (
								<FormHelperText error>Feltet må ikke være tomt</FormHelperText>
							) : (
								<FormHelperText> </FormHelperText>
							)}
						</FormControl>
						<TextField
							id="amount"
							label="Beløb"
							margin="normal"
							required
							fullWidth
							value={toComma(values.amount)}
							onChange={handleAmountChange}
							onBlur={handleBlur}
							error={!!errors.amount && touched.amount}
							helperText={errors.amount && touched.amount ? errors.amount : ' '}
							InputProps={{
								endAdornment: (
									<InputAdornment onClick={() => console.log('clicked kr')} position="end">
										Kr
									</InputAdornment>
								),
							}}
						/>
						{requiresParticipantList(values.purpose) && (
							<TextField
								id="participants"
								label="Deltagere"
								margin="normal"
								required
								multiline
								fullWidth
								value={values.participants}
								onChange={handleChange}
								onBlur={handleBlur}
								error={!!errors.participants && touched.participants}
								helperText={
									errors.participants && touched.participants
										? errors.participants
										: 'Ved forplejning kræves en deltagerliste'
								}
							/>
						)}
					</CenteringGrid>
				</Grid>
			</DetailsPanel>
			<Divider />
			<ExpansionPanelActions>
				<DeleteButton onApproved={deleteExpense} />
				<Button id={'save-button'} disabled={!validateExpense(values)} size="small" color="primary" onClick={submit}>
					Gem
				</Button>
			</ExpansionPanelActions>
		</ExpansionPanel>
	)
}

const PanelSummary = styled(ExpansionPanelSummary)<{ validAndClosed?: boolean }>`
	background-color: ${({ validAndClosed }) => (validAndClosed ? '#cbfdcb' : 'white')};
`
const CenteringContainer = styled.div`
	justify-content: center;
	display: flex;
	align-content: center;
`
const CenteringGrid = styled(Grid)`
	display: flex;
	flex-direction: column;
	justify-content: center;
`
