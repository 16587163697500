export type PersonalInput = {
	name: string
	mail: string
	regNr: string | number
	accNr: string | number
	organisation: string | number
	budgetNumber: string | number
}
export const defaultPersonal: PersonalInput = {
	name: '',
	mail: '',
	regNr: '',
	accNr: '',
	organisation: '',
	budgetNumber: '',
}
