import { PaddedPaper } from './CommonStyledComponents'
import Typography from '@material-ui/core/Typography'
import { PersonalSummary } from './PersonalSummary'
import { DriveSummary } from './Drive/DriveSummary'
import { ExpenseSummary } from './Expense/ExpenseSummary'
import React from 'react'
import { Drive } from '../model/Drive'
import { PersonalInput } from '../model/PersonalInput'
import { Expense } from '../model/Expense'

type SummaryPageProps =
	| {
			personal: PersonalInput
	  } & (
			| {
					reimbursement: 'drive'
					drives: Drive[]
			  }
			| {
					reimbursement: 'expense'
					expenses: Expense[]
			  }
	  )

export function SummaryPage(props: SummaryPageProps) {
	return (
		<>
			<PaddedPaper>
				<Typography variant="h6" gutterBottom color="secondary">
					Ser det hele rigtigt ud?
				</Typography>
				<Typography variant="body1" gutterBottom>
					Tjek venligst indtastet data igennem inden det bliver sendt. Skulle der være fejl, kan du bare trykke tilbage
					og rette dem.
				</Typography>
			</PaddedPaper>
			<PersonalSummary state={props.personal} />
			{props.reimbursement === 'drive' ? (
				<DriveSummary drives={props.drives} />
			) : (
				<ExpenseSummary expenses={props.expenses} />
			)}
		</>
	)
}
