import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import { PaddedPaper } from './CommonStyledComponents'
import styled from 'styled-components'
import { PersonalInput } from '../model/PersonalInput'
import { getOrganisationName, Organisation } from '../model/api/Details'

type PersonalSummaryProps = {
	state: PersonalInput
}

export function PersonalSummary({ state }: PersonalSummaryProps) {
	const { name, mail, regNr, accNr, budgetNumber, organisation } = state

	return (
		<SummaryPaddedPaper>
			<Grid container>
				<Grid item justify={'center'} xs={6}>
					<Container>
						<Inline>
							<Typography color="secondary">Navn</Typography>
							<Typography gutterBottom>{name}</Typography>
						</Inline>

						<Inline>
							<Typography color="secondary">Email</Typography>
							<Typography gutterBottom>{mail}</Typography>
						</Inline>
						<Inline>
							<Typography color="secondary">Konto</Typography>
							<Typography gutterBottom>{`${regNr} ${accNr}`}</Typography>
						</Inline>
					</Container>
				</Grid>
				<Grid item justify={'center'} xs={6}>
					<Container>
						{budgetNumber &&
						<Inline>
							<Typography color="secondary">Budget-/Afdelingsnummer</Typography>
							<Typography gutterBottom>{budgetNumber}</Typography>
						</Inline>
						}
						<Inline>
							<Typography color="secondary">Organisation</Typography>
							<Typography gutterBottom>{getOrganisationName(organisation as Organisation)}</Typography>
						</Inline>
					</Container>
				</Grid>
			</Grid>
		</SummaryPaddedPaper>
	)
}

const Container = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
`
const Inline = styled.div`
	padding-bottom: 10px;
`
const SummaryPaddedPaper = styled(PaddedPaper)`
	margin-top: 24px;
`
