import Button from '@material-ui/core/Button'
import React from 'react'
import styled from 'styled-components'
import { RFTheme } from './RFTheme'

type ButtonBarProps = {
	nextAllowed: boolean
	backAllowed: boolean
	onNextClicked: () => void
	onBackClicked: () => void
	readyToSend: boolean
}

export function ButtonBar(props: ButtonBarProps) {
	const { nextAllowed, backAllowed, onNextClicked, onBackClicked, readyToSend } = props
	return (
		<Container>
			<BackButton disabled={!backAllowed} onClick={onBackClicked} size="large">
				Tilbage
			</BackButton>
			<NextButton
				id={'next-button'}
				variant="contained"
				color="primary"
				onClick={onNextClicked}
				size="large"
				disabled={!nextAllowed}
			>
				{readyToSend ? 'Send' : 'Næste'}
			</NextButton>
		</Container>
	)
}

const Container = styled.div`
	margin-top: 32px;
	display: flex;
	justify-content: center;
`

const BackButton = styled(Button)`
	margin-right: ${RFTheme.spacing(1)}px;
`
const NextButton = styled(Button)`
	color: white;
`
