import React from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import { getPurposeById } from '../../utils/Purposes'
import { Expense } from '../../model/Expense'
import styled from 'styled-components'
import { BoldTableCell, SummaryPaper, SummaryTable } from '../CommonStyledComponents'
import { ReceiptDialog } from './ReceiptDialog'
import { Attachment, LinkOff } from '@material-ui/icons'
import { hideCommaNoNumberAfter } from '../../utils/NumberConverter'

type Row = {
	date: string
	amount: number | string
	purpose: string
	name: number
	expense: Expense
}

function createRows(expenses: Expense[]) {
	return expenses.map((d, i) => createRow(d, i))
}

function createRow(expense: Expense, i: number): Row {
	const date = moment(expense.date).format('D. MMMM')
	const purpose = getPurposeById(expense.purpose)
	const name = i + 1
	const amount = expense.amount

	return { date, purpose, expense, name, amount }
}

function calcTotal(items: Expense[]) {
	return items
		.map(({ amount }) => (typeof amount === 'string' ? parseFloat(amount) : amount))
		.reduce((sum, i) => sum + i, 0)
}

const invoiceTotal = (expenses: Expense[]) => (expenses !== undefined ? calcTotal(expenses) : 0)
const replaceWithComma = (number: number | string) => (number + '').replace('.', ',')

type ExpenseSummaryProps = {
	expenses: Expense[]
}

function ExpenseName({ row }: { row: Row }) {
	const [open, setOpen] = React.useState(false)
	const handleOpen = () => setOpen(true)
	const handleClose = () => {
		setOpen(false)
	}
	return (
		<ExpenseNameCell clickable={!row.expense.lost} onClick={handleOpen}>
			{row.expense.lost ? <LinkOff /> : <Attachment />}
			<ExpenseText>Udgift #{row.name}</ExpenseText>
			{!row.expense.lost && (
				<ReceiptDialog open={open} onClose={handleClose} receiptName={`Udgift #${row.name}`} file={row.expense.file} />
			)}
		</ExpenseNameCell>
	)
}

export function ExpenseSummary({ expenses }: ExpenseSummaryProps) {
	const rows = createRows(expenses)
	const total = replaceWithComma(invoiceTotal(expenses))

	return (
		<SummaryPaper>
			<SummaryTable>
				<TableHead>
					<TableRow>
						<TableCell>
							<Typography>Udgift</Typography>
						</TableCell>
						<TableCell>
							<Typography>Dato</Typography>
						</TableCell>
						<TableCell>
							<Typography>Formål</Typography>
						</TableCell>
						<TableCell align="right">
							<Typography>Beløb</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row.name}>
							<ExpenseName row={row} />
							<TableCell>
								<Typography>{row.date}</Typography>
							</TableCell>
							<TableCell>
								<Typography>{row.purpose}</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography>{hideCommaNoNumberAfter(row.amount)} kr</Typography>
							</TableCell>
						</TableRow>
					))}
					<TableRow>
						<TableCell rowSpan={6} />
						<TableCell rowSpan={6} />
					</TableRow>
					<TableRow>
						<BoldTableCell>
							<Typography>Til udbetaling</Typography>
						</BoldTableCell>
						<BoldTableCell align="right">
							<Typography>{total} kr</Typography>
						</BoldTableCell>
					</TableRow>
				</TableBody>
			</SummaryTable>
		</SummaryPaper>
	)
}

const ExpenseText = styled(Typography)`
	padding-left: 5px;
`
const ExpenseNameCell = styled(TableCell)<{ clickable: boolean }>`
	display: flex;
	flex-direction: row;
	${({ clickable }) => clickable && 'cursor: pointer;'}
`
