import React, { useEffect } from 'react'

import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useFormik } from 'formik'
import { personalSchema } from '../utils/Schemes'
import { Gab, PaddedPaper } from './CommonStyledComponents'
import { PersonalInput } from '../model/PersonalInput'
import styled from 'styled-components'

type PersonalProps = {
	onChange: (value: PersonalInput) => void
	state: PersonalInput
}

export function PersonalPage({ onChange, state }: PersonalProps) {
	const { values, touched, errors, handleChange, handleBlur } = useFormik<PersonalInput>({
		initialValues: state,
		validationSchema: personalSchema,
		onSubmit: console.log,
	})
	useEffect(() => {
		onChange(values)
	}, [values, onChange])

	return (
		<PaddedPaper>
			<Typography variant="h6" color="secondary">
				Personlige oplysninger
			</Typography>
			<TextField
				id="name"
				label="Fulde navn"
				onChange={handleChange}
				onBlur={handleBlur}
				error={!!errors.name && !!touched.name}
				helperText={errors.name && touched.name ? errors.name : ' '}
				required
				fullWidth
				value={values.name}
			/>
			<TextField
				id="mail"
				label="Mail"
				error={!!errors.mail && !!touched.mail}
				helperText={errors.mail && touched.mail ? errors.mail : ' '}
				required
				fullWidth
				value={values.mail}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			<RegNrField
				id="regNr"
				label="Reg nr."
				required
				error={!!errors.regNr && !!touched.regNr}
				helperText={errors.regNr && touched.regNr ? errors.regNr : ' '}
				value={values.regNr}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			<AccNrField
				id="accNr"
				label="Konto nr."
				required
				error={!!errors.accNr && !!touched.accNr}
				helperText={errors.accNr && touched.accNr ? errors.accNr : ' '}
				value={values.accNr}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			<Gab />
			<Typography variant="h6" color="secondary">
				Detaljer
			</Typography>
			<FormControl fullWidth required error={!!errors.organisation && !!touched.organisation} id={'organisation'}>
				<InputLabel htmlFor="organisation">Organisation</InputLabel>
				<Select
					fullWidth
					inputProps={{
						id: 'organisation',
						name: 'organisation',
					}}
					value={values.organisation}
					onChange={handleChange}
					onBlur={handleBlur}
				>
					<MenuItem value={0}>
						<em>Ingen</em>
					</MenuItem>
					<MenuItem value={1}>Festival</MenuItem>
					<MenuItem value={2}>Fonden/RFX</MenuItem>
					<MenuItem value={3}>Kulturservice (RKS)</MenuItem>
				</Select>
				{errors.organisation && touched.organisation ? (
					<FormHelperText error>Feltet må ikke være tomt</FormHelperText>
				) : (
					<div />
				)}
			</FormControl>
			{values.organisation !== 3 ? (
				<TextField
					id="budgetNumber"
					label="Budget-/Afdelingsnummer"
					fullWidth
					required
					margin="normal"
					error={!!errors.budgetNumber && !!touched.budgetNumber}
					helperText={errors.budgetNumber && touched.budgetNumber ? errors.budgetNumber : ' '}
					value={values.budgetNumber}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
			) : (
				<Gab />
			)}
		</PaddedPaper>
	)
}

const RegNrField = styled(TextField)`
	width: 30%;
`
const AccNrField = styled(TextField)`
	width: 70%;
`
