import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import styled from 'styled-components'
import { PaddedPaper } from './CommonStyledComponents'
import { Reimbursement } from '../model/Reimbursement'

type ReimbursementSelectionProps = {
	selection?: Reimbursement
	onChange: (reimbursement?: Reimbursement) => void
}

export function ReimbursementSelection({ selection, onChange }: ReimbursementSelectionProps) {
	const wrappedOnChange = (reimbursement: string) => {
		if (reimbursement === '') onChange(undefined)
		else onChange(reimbursement as Reimbursement)
	}

	return (
		<PaddedPaper>
			<MarginTypography variant="h6" color="secondary" gutterBottom>
				Hvad skal du have godtgjort?
			</MarginTypography>
			<StyledFormControl variant="outlined">
				<Select
					value={selection ?? ''}
					onChange={({ target: { value } }) => wrappedOnChange(value as string)}
					input={<OutlinedInput name="reimbursement" />}
				>
					<MenuItem value="">
						<em>Vælg godtgørelse</em>
					</MenuItem>
					<MenuItem value={'drive'}>Kørsel</MenuItem>
					<MenuItem value={'expense'}>Udlæg</MenuItem>
				</Select>
			</StyledFormControl>
		</PaddedPaper>
	)
}

const StyledFormControl = styled(FormControl)`
	width: 100%;
	min-width: 120px;
`

const MarginTypography = styled(Typography)`
	margin-bottom: 20px;
`
