import { LostReceipt } from '../model/LostReceipt'
import { Expense } from '../model/Expense'
import { ReceiptFile } from '../model/ReceiptFile'

export const defaultExpense = (f: ReceiptFile): Expense => ({
	amount: '',
	date: Date.now(),
	purpose: '',
	lost: false,
	file: f,
	participants: '',
})

export const defaultLostExpense = (lostReceipt: LostReceipt): Expense => ({
	amount: '',
	date: Date.now(),
	purpose: '',
	lost: true,
	lostReceipt: lostReceipt,
	participants: '',
})
