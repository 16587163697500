import { useEffect, useRef } from 'react'

export const useBlockUnload = (blockUnload: boolean) => {
	const listener = useRef<EventListener>()

	useEffect(() => {
		listener.current = (event: BeforeUnloadEvent) => {
			// This will make the browser block the unload event until the user confirms
			// https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
			if (blockUnload) {
				event.preventDefault()
				event.returnValue = ''
			}
		}
	}, [blockUnload])

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => listener.current?.(event)

		window.addEventListener('beforeunload', handleBeforeUnload)
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [])
}
