import { useEffect, useState } from 'react'
import { AddressResponse } from '../../model/AddressResponse'
import { useAddressContext } from '../../utils/AddressContext'

async function fetchAddresses(searchString: string) {
	let url = `https://dawa.aws.dk/adresser/autocomplete?q=${searchString}&per_side=5`
	const response = await fetch(url)
	const addressResponse = (await response.json()) as AddressResponse[]

	const addresses = addressResponse.map(({ adresse: a }) => `${a.vejnavn} ${a.husnr}, ${a.postnr} ${a.postnrnavn}`)

	return Array.from(new Set(addresses))
}

export function useAddresses(searchString: string) {
	const [addresses, setAddresses] = useState<string[]>([])
	const addressContext = useAddressContext()
	useEffect(() => {
		if (!searchString) setAddresses(addressContext.addresses)
		else fetchAddresses(searchString).then(setAddresses)
	}, [searchString, addressContext.addresses])
	return addresses
}
