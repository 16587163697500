import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import React from 'react'
import styled from 'styled-components'

type StepperProps = {
	steps: string[]
	activeStep: number
}

export function OverviewStepper({ steps, activeStep }: StepperProps) {
	return (
		<Container>
			<StyledStepper activeStep={activeStep} alternativeLabel>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</StyledStepper>
		</Container>
	)
}

const Container = styled.div`
	min-width: 80%;
`

const StyledStepper = styled(Stepper)`
	background-color: transparent;
`
