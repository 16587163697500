import Grid from '@material-ui/core/Grid'
import { RFLogo } from '../RFLogo'
import { OverviewStepper } from '../OverviewStepper'
import { PaddedPaper } from '../CommonStyledComponents'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import { RateForm } from './RateForm'
import { useAuthenticationMutation } from '../../api/useAuthenticationMutation'
import { useSnackbar } from 'notistack'
import { ErrorOptions } from '../../utils/snackbarOptions'
import { useVerificationMutation } from '../../api/useVerificationMutation'
import { StatusCodeError } from '../../utils/statusCodeError'
import { useUpdateRateMutation } from '../../api/useUpdateRateMutation'

export function AdminPage() {
	const [activeStep, setActiveStep] = useState(0)
	const [authenticationCode, setAuthenticationCode] = useState<number>()
	const { enqueueSnackbar } = useSnackbar()
	const { mutate: authenticate, isLoading: isAuthenticationLoading } = useAuthenticationMutation(
		() => setActiveStep(1),
		() => enqueueSnackbar('Mail afsendelse fejlede. Prøv igen.', ErrorOptions)
	)
	const onVerificationError = (error: StatusCodeError) => {
		if (error.statusCode === 401) enqueueSnackbar('Noget gik galt. Prøv igen.', ErrorOptions)
		else enqueueSnackbar('Verifikation fejlede. Prøv igen.', ErrorOptions)
	}
	const { mutate: verify, isLoading: isVerifyLoading } = useVerificationMutation(
		() => setActiveStep(2),
		onVerificationError
	)
	const { mutate: updateRate, isLoading: isUpdateRateLoading } = useUpdateRateMutation(
		() => setActiveStep(3),
		() => enqueueSnackbar('Opdatering af sats fejlede. Prøv igen.', ErrorOptions)
	)

	const isLoading = isAuthenticationLoading || isVerifyLoading || isUpdateRateLoading

	function onVerification(code: string) {
		const codeAsNumber = Number.parseInt(code)
		if (isNaN(codeAsNumber)) return enqueueSnackbar('Koden må kun være tal.', ErrorOptions)
		setAuthenticationCode(codeAsNumber)
		verify(codeAsNumber)
	}

	function onRateUpdate(rate: number) {
		if (!authenticationCode) return enqueueSnackbar('Der er ikke indtastet en kode.', ErrorOptions)

		updateRate({ authenticationCode, rate })
	}

	return (
		<Grid alignItems="center" container>
			<Grid item xs={12}>
				<RFLogo />
				<MainContainer>
					<OverviewStepper steps={['Introduktion', 'Login', 'Opdater sats']} activeStep={activeStep} />
					<Container maxWidth="sm">
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<PaddedPaper>
									<Typography variant="h6" gutterBottom color="secondary">
										Opdatering af sats for kørsel
									</Typography>
									<RateForm
										activeStep={activeStep}
										isLoading={isLoading}
										onSendMail={authenticate}
										onVerification={onVerification}
										onRateUpdate={onRateUpdate}
									/>
								</PaddedPaper>
							</Grid>
						</Grid>
					</Container>
				</MainContainer>
			</Grid>
		</Grid>
	)
}

const MainContainer = styled(Grid)`
	display: flex;
	flex-direction: column;
	align-items: center;
`
