import imageCompression from 'browser-image-compression'

const compressionOptions = {
	maxSizeMB: 1,
	maxWidthOrHeight: 1920,
	useWebWorker: true,
}

export async function compressImage(imageFile: File) {
	try {
		return await imageCompression(imageFile, compressionOptions)
	} catch (error) {
		console.log(`Failed to compress image: ${error}`)
		return imageFile
	}
}
