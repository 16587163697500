import { createDriveJSON, createExpenseJSON } from './JsonFormatter'
import { logError } from './ErrorLogger'
import { PersonalInput } from '../model/PersonalInput'
import { Drive } from '../model/Drive'
import { Expense } from '../model/Expense'
import { useMutation } from 'react-query'

const api_host = `api/api`
const driveRoute = api_host + '/Drive'
const expenseRoute = api_host + '/Expense'

async function request(body: string, route: string) {
	try {
		if (process.env.REACT_APP_TESTING) {
			await new Promise((r) => setTimeout(r, 2000))
			return { status: 200 } as Response
		}

		const response = await fetch(route, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: body,
		})
		if (!response.ok) throw new Error(response.statusText)

		return response
	} catch (error) {
		logError(error)
		throw error
	}
}

async function sendDriveRequest(personal: PersonalInput, drives: Drive[]): Promise<Response> {
	const body = JSON.stringify(createDriveJSON(personal, drives))
	return await request(body, driveRoute)
}

async function sendExpenseRequest(personal: PersonalInput, expenses: Expense[]) {
	const bodyObject = createExpenseJSON(personal, expenses)
	const toBePrinted = bodyObject.expenses.map(({ attachment, ...rest }) => rest)
	const body = JSON.stringify(bodyObject)
	console.log(bodyObject.details, toBePrinted)
	return await request(body, expenseRoute)
}

export const useExpenseMutation = () =>
	useMutation(
		({ personal, expenses }: { personal: PersonalInput; expenses: Expense[] }) =>
			sendExpenseRequest(personal, expenses),
		{ retry: 3, retryDelay: (attempt) => attempt * 1000 }
	)

export const useDriveMutation = () =>
	useMutation(
		({ personal, drives }: { personal: PersonalInput; drives: Drive[] }) => sendDriveRequest(personal, drives),
		{ retry: 3, retryDelay: (attempt) => attempt * 1000 }
	)
