export enum Organisation {
	Festival = 1,
	Fond = 2,
	KulturService = 3,
}

export function getOrganisationName(organisation: Organisation) {
	switch (organisation) {
		case Organisation.Festival:
			return 'Festival'
		case Organisation.Fond:
			return 'Fonden/RFX'
		case Organisation.KulturService:
			return 'Kulturservice (RKS)'
	}
}

export type Details = {
	name: string
	email: string
	registrationNumber: string
	accountNumber: string
	organisation: Organisation
	budgetNumber?: number
}
