import { OptionsObject } from 'notistack'

export const ErrorOptions: OptionsObject = {
  variant: 'error',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
}
export const SuccessOptions: OptionsObject = {
  variant: 'success',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
}
