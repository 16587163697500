import { useMutation } from 'react-query'

export function useAuthenticationMutation(onSuccess?: () => void, onError?: () => void, onSettled?: () => void) {
	return useMutation(
		async () => {
			const { ok } = await fetch(`api/api/admin/authenticate`, {
				method: 'POST',
			})
			if (!ok) throw new Error('Failed to fetch')
		},
		{
			onSuccess: () => onSuccess && onSuccess(),
			onError: () => onError && onError(),
			onSettled: () => onSettled && onSettled(),
		}
	)
}
