import { SnackbarProvider as NotistackSnackbarProvider, SnackbarProviderProps } from 'notistack'
import React from 'react'

export function SnackbarProvider(props: SnackbarProviderProps) {
	return (
		<NotistackSnackbarProvider
			{...props}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
		/>
	)
}
