import * as Yup from 'yup'
import { requiresParticipantList } from './Purposes'
import { Drive } from '../model/Drive'

export const defaultDrive = (id: number): Drive => ({
	identity: id,
	date: Date.now(),
	fromAddress: '',
	toAddress: '',
	viaAddress: '',
	purpose: '',
	distance: '',
	valid: false,
	saved: false,
	estimatedDistance: '',
})

export const driveSchema = Yup.object().shape({
	fromAddress: Yup.string().required('Fra adresse må ikke være tom'),
	toAddress: Yup.string()
		.test('match', 'Til adressen må ikke være den samme som fra adressen', function (toAddress) {
			// @ts-ignore
			return toAddress !== this.parent.fromAddress
		})
		.required('Til adresse må ikke være tom'),
	viaAddress: Yup.string(),
	date: Yup.string().required('Dato må ikke være tom'),
	purpose: Yup.string().required('Reg nr. må udelukkende være tal'),
	distance: Yup.number()
		.integer('Distance må udelukkende være tal (ingen kommatal)')
		.min(13, 'Distancen skal være minimum 13 km')
		.typeError('Distance må udelukkende være tal (ingen kommatal)')
		.required('Distance må ikke være tom'),
})

function isNumeric(str?: string | null) {
	if (typeof str != 'string') return false // we only process strings!
	return (
		// @ts-ignore
		!isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str))
	) // ...and ensure strings of whitespace fail
}

export const personalSchema = Yup.object().shape({
	name: Yup.string().required('Navn må ikke være tomt'),
	mail: Yup.string().email('Mail ikke gyldig').required('Mail må ikke være tom'),
	regNr: Yup.string()
		.matches(/^[0-9]{4}$/, 'Reg nr. må udelukkende bestå af fire cifre')
		.required('Konto nr. må ikke være tom'),
	accNr: Yup.string()
		.matches(/^[0-9]{6,10}$/, 'Konto nr. må udelukkende være (mellem 6-10) tal')
		.required('Konto nr. må ikke være tom'),
	organisation: Yup.number().min(1).required('Feltet er påkrævet'),
	budgetNumber: Yup.string().test(
		'match',
		'Budgetnummer er påkrævet hvis det ikke er for Kulturservice',
		function (budgetNumber) {
			if (this.parent.organisation === 3) return true
			return budgetNumber !== '' && budgetNumber !== undefined && isNumeric(budgetNumber)
		}
	),
})

export const expenseSchema = Yup.object().shape({
	amount: Yup.number().typeError('Beløb skal være tal').required('Beløb må ikke være tom'),
	date: Yup.string().required('Dato må ikke være tom'),
	purpose: Yup.string().required('Formål må ikke være tomt'),
	participants: Yup.string().test(
		'match',
		'En deltagerliste er påkrævet hvis formålet er Forplejning',
		function (participants) {
			if (!requiresParticipantList(this.parent.purpose)) return true
			return participants !== '' && participants !== undefined
		}
	),
	comment: Yup.string(),
})

export const lostReceiptSchema = Yup.object().shape({
	description: Yup.string().required('Beskrivelse må ikke være tomt'),
	reason: Yup.string().required('Årsag må ikke være tomt'),
})
