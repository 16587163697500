import React from 'react'
import styled from 'styled-components'
import logo from '../assets/images/rf-logo.png'

export function RFLogo() {
	return (
		<LogoContainer>
			<img width={'auto'} height={100} src={logo} alt="" />
		</LogoContainer>
	)
}

const LogoContainer = styled.div`
	margin-top: 24px;
	margin-bottom: 24px;
	display: flex;
	justify-content: center;
`
