import React from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import 'typeface-noto-sans'
import Dropzone from 'react-dropzone'
import { CloudPlaying, CloudStopped } from './CloudAnimations'
import Card from '@material-ui/core/Card'
import { createFile } from './ExpenseUtils'
import { LostReceiptDialog } from './LostReceiptDialog'
import { LostReceipt } from '../../model/LostReceipt'
import { ReceiptFile } from '../../model/ReceiptFile'
import styled, { css } from 'styled-components'

function renderDefault(open: () => void, compact: boolean) {
	return (
		<DragzoneContainer compact={compact} onClick={() => compact && open()}>
			<CloudContainer>
				<CloudStopped />
			</CloudContainer>
			<Headline>{!compact ? "Drag 'n' drop kvitteringer" : 'Flere kvitteringer?'}</Headline>
			{!compact && (
				<span>
					<SubText>eller klik for at uploade kvitteringer</SubText>
					<UploadButton onClick={open} startIcon={<WhiteCloudIcon />} variant="contained" color="primary">
						<UploadButtonText>Vælg filer</UploadButtonText>
					</UploadButton>
				</span>
			)}
			<SupportText>Vi kan forstå PNG, JPG og PDF</SupportText>
		</DragzoneContainer>
	)
}

function renderDragging(compact: boolean) {
	return (
		<DragzoneContainer compact={compact}>
			<CloudPlayingContainer compact={compact}>
				<CloudPlaying />
			</CloudPlayingContainer>
			{!compact && (
				<span>
					<Headline>Slip for at uploade kvitteringer</Headline>
					<SubText>Vi kan forstå PNG, JPG og PDF</SubText>
				</span>
			)}
		</DragzoneContainer>
	)
}

type ReceiptDragZoneProps = {
	onFileAdded: (file: ReceiptFile) => void
	onDropComplete: (batchSize: number) => void
	onLostReceiptAdded: (lostReceipt: LostReceipt) => void
	onFileRejected: (file: File) => void
	onFileTooLarge: (file: File) => void
	compact: boolean
}
const maxPdfSize = 5 * 1024 * 1024
const acceptedFileTypes = 'image/jpeg, image/png, .pdf'

const notTooBigPDF = (file: File) => {
	if (file.type === 'application/pdf') return file.size <= maxPdfSize
	return true
}

export function ReceiptDropzone(props: ReceiptDragZoneProps) {
	const { onFileAdded, onLostReceiptAdded, onDropComplete, compact, onFileRejected, onFileTooLarge } = props

	const filesDropped = async (accepted: File[], rejected: File[]) => {
		const acceptedFiles = accepted.filter(notTooBigPDF)
		const tooBigPDFs = accepted.filter((file) => !notTooBigPDF(file))

		await onDropAccepted(acceptedFiles)
		tooBigPDFs.forEach(onFileTooLarge)
		rejected.forEach(onFileRejected)
	}

	const onDropAccepted = async (files: File[]) => {
		await Promise.all(files.map((f) => createFile(f).then((f) => onFileAdded(f))))
		onDropComplete(files.length)
	}

	return (
		<Card>
			<LostReceiptDialog onSubmit={(lostReceipt) => onLostReceiptAdded(lostReceipt)} />
			<Dropzone noClick onDrop={filesDropped} accept={acceptedFileTypes}>
				{({ getRootProps, getInputProps, isDragActive, open }) => (
					<MainContainer {...getRootProps({ className: 'dropzone' })} compact={compact}>
						<input id={'dropzone-input'} {...getInputProps()} />
						{!isDragActive ? renderDefault(open, compact) : renderDragging(compact)}
					</MainContainer>
				)}
			</Dropzone>
		</Card>
	)
}

const DragzoneCss = css`
	align-items: center;
	justify-content: center;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	flex: 1;
	text-align: center;
	height: 100%;
`

const DragzoneContainer = styled.div<{ compact: boolean }>`
	${DragzoneCss};
	padding: ${({ compact }) => (compact ? '0px' : '50px')};
`

const MainContainer = styled.div<{ compact: boolean }>`
	${DragzoneCss};
	height: ${({ compact }) => (compact ? '200px' : '500px')};
`

const CloudContainer = styled.div`
	width: 180px;
	height: auto;
	margin-bottom: -50px;
	margin-top: -50px;
`

const CloudPlayingContainer = styled.div<{ compact: boolean }>`
	width: 300px;
	height: auto;
	margin-bottom: -80px;
	margin-top: ${({ compact }) => (compact ? '-80px' : '-150px')};
`

const Headline = styled(Typography)`
	font-family: 'Noto Sans', sans-serif;
	font-size: 1.125rem;
	font-weight: 700;
	letter-spacing: 0.9px;
	margin-top: 15px;
	color: black;
`

const SubText = styled(Typography)`
	font-family: 'Noto Sans', sans-serif;
	color: black;
`

const WhiteCloudIcon = styled(CloudUploadIcon)`
	color: #fff;
`

const UploadButton = styled(Button)`
	margin-top: 30px;
	padding: 1rem 1.5rem;
	color: #fff;
`

const UploadButtonText = styled(Typography)`
	font-family: 'Noto Sans', sans-serif;
	font-weight: 600;
	color: white;
`

const SupportText = styled(Typography)`
	font-family: 'Noto Sans', sans-serif;
	padding-top: 10px;
	color: #ed7303;
	font-size: 0.875rem;
	font-style: italic;
`
