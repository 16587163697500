import { AddDrive } from './AddDrive'
import React, { useState } from 'react'
import { Drive } from '../../model/Drive'
import { changeDrive, createNewDrive, deleteDrive, duplicateDrive, saveDrive } from '../../utils/DriveUtils'
import { DriveInput } from './DriveInput'

type DrivePageProps = {
	drives: Drive[]
	onChange: (value: Drive[]) => void
	driveCounter: number
	onDriveCounterChange: (count: number) => void
}

export function DrivePage(props: DrivePageProps) {
	const { drives, driveCounter, onChange, onDriveCounterChange } = props
	const [expanded, setExpanded] = useState(0)

	const onDelete = (identity: number) => onChange(deleteDrive(identity, drives))

	const onAddNew = () => {
		onChange(createNewDrive(driveCounter + 1, drives))
		onDriveCounterChange(driveCounter + 1)
		setExpanded(driveCounter + 1)
	}

	const onSubmit = (identity: number, value: Drive, createReturn: boolean) => {
		onChange(saveDrive(identity, value, drives, createReturn, driveCounter + 1))
		if (createReturn) onDriveCounterChange(driveCounter + 1)
		setExpanded(-1)
	}

	const onChangeDrive = (identity: number, value: Drive) => onChange(changeDrive(identity, value, drives))

	const onDuplicate = (newState: Drive, identity: number) => {
		onChange(duplicateDrive(newState, identity, drives, driveCounter + 1))
		onDriveCounterChange(driveCounter + 1)
	}
	const handleExpanded = (id: number) => {
		if (expanded === id) setExpanded(-1)
		else setExpanded(id)
	}
	return (
		<>
			{drives.map((d) => (
				<DriveInput
					key={d.identity}
					id={d.identity}
					state={d}
					onSubmit={onSubmit}
					onChange={onChangeDrive}
					onDelete={onDelete}
					onDuplicate={onDuplicate}
					isDeletable={drives.length !== 1}
					expanded={expanded === d.identity}
					toggleExpanded={handleExpanded}
				/>
			))}
			<AddDrive onClick={onAddNew} />
		</>
	)
}
