import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import 'core-js/features/array/find'
import 'core-js/features/array/includes'
import 'core-js/features/number/is-nan'
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import { detect } from 'detect-browser'
import { UnsupportedBrowser } from './components/UnsupportedBrowser'

const browser = detect()

Sentry.init({
	dsn: 'https://3431be91d5274dda9976d27d4a119b18@o573520.ingest.sentry.io/6479620',
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
	// set by docker-compose for build inline-ing
	environment: process.env.REACT_APP_ENV || 'development',
})

if (browser.name === 'ie') ReactDOM.render(<UnsupportedBrowser />, document.getElementById('root'))
else ReactDOM.render(<App />, document.getElementById('root'))
