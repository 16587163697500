import { createInstance } from '@datapunt/matomo-tracker-react'
import { Reimbursement } from '../model/Reimbursement'
import { steps } from '../LandingPage'
import { TrackPageViewParams } from '@datapunt/matomo-tracker-react/lib/types'

export const matomoInstance = createInstance({
	urlBase: 'https://matomo.rfit.dk',
	siteId: 3,
	heartBeat: {
		// optional, enabled by default
		active: true, // optional, default value: true
		seconds: 10, // optional, default value: `15
	},
	configurations: {
		disableCookies: true,
	},
})

function addReimbursementType(stepName: string, reimbursement?: Reimbursement) {
	return reimbursement === 'drive' ? `${stepName} - Kørsel` : `${stepName} - Udlæg`
}

function getStepName(activeStep: number, reimbursement?: Reimbursement): string {
	const stepName = steps[activeStep]

	if (activeStep === 0) return '1. Forside'
	if (activeStep === 1 || activeStep === 2 || activeStep === 4)
		return `${activeStep + 1}. ${addReimbursementType(stepName, reimbursement)}`
	if (activeStep === 5) return '6. Behandler'

	return `${activeStep + 1}. ${steps[activeStep]}`
}

export function trackStep(
	trackPageView: (params: TrackPageViewParams) => void,
	activeStep: number,
	reimbursement?: Reimbursement
) {
	trackPageView({
		documentTitle: getStepName(activeStep, reimbursement),
	})
}
