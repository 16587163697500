import React, { Component } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import throttle from 'lodash.throttle'

//This is a hack to get it to load the pdfs
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const range = (end) => new Array(end).fill(undefined).map((_, i) => i + 1)

export default class PDFPreview extends Component {
	state = { numPages: 0, PDFWidth: null }

	onDocumentLoadSuccess = ({ numPages }) => this.setState({ numPages: numPages })

	constructor(props) {
		super(props)
		this.myInput = React.createRef()
	}

	componentDidMount() {
		// setting width at initial
		this.setPDFWidth()

		// event listener when window is resized
		window.addEventListener('resize', throttle(this.setPDFWidth, 500))
	}

	componentWillUnmount() {
		window.removeEventListener('resize', throttle(this.setPDFWidth, 500))
	}

	setPDFWidth = () => {
		const width = this.myInput?.current?.offsetWidth
		if (width) this.setState({ PDFWidth: width })
	}

	render() {
		const { PDFWidth, numPages } = this.state
		const { file } = this.props
		return (
			<div ref={this.myInput} style={{ width: '95%' }}>
				<Document loading={<div />} file={file} onLoadSuccess={this.onDocumentLoadSuccess}>
					{range(numPages).map((x, i) => (
						<Page key={i} width={PDFWidth} pageNumber={x} />
					))}
				</Document>
			</div>
		)
	}
}
