import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import React from 'react'
import styled from 'styled-components'
import { ThankYouAnimation } from './ThankYouAnimation'

type ThankYouDialogProps = {
	open: boolean
	personal: { name: string; mail: string }
}

export function ThankYouDialog({ open, personal }: ThankYouDialogProps) {
	const handleClose = () => window.location.reload()

	const firstname = personal.name.split(' ')[0]

	return (
		<Dialog
			fullWidth={true}
			maxWidth={'md'}
			open={open}
			onClose={handleClose}
			aria-labelledby="max-width-dialog-title"
			id="thank-you-dialog"
		>
			<ThankYouAnimation />
			<Title variant={'h4'}>Du har fået mail{`, ${firstname}.`}</Title>
			<DialogContent>
				<Text variant={'body1'}>
					Din forespørgsel er nu sendt til bogholderiet, og du behøver ikke foretage dig yderligere.
					<br />
					En kopi er sendt til {personal.mail}.
				</Text>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" id="thank-you-ok-button">
					OK
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const Title = styled(Typography)`
	text-align: center;
	font-size: 2em !important;
	padding-top: 0;
	padding-bottom: 1rem;
`
const Text = styled(Typography)`
	text-align: center;
`
