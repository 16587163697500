import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import IconButton from '@material-ui/core/IconButton'
import AddCircle from '@material-ui/icons/Add'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import React from 'react'
import styled from 'styled-components'

type AddDriveProps = {
	onClick: () => void
}

export function AddDrive({ onClick }: AddDriveProps) {
	return (
		<ExpansionPanel expanded={false}>
			<GrayPanel onClick={onClick}>
				<NoPadIconButton size={'medium'} aria-label="delete" color="primary">
					<AddCircle />
				</NoPadIconButton>
			</GrayPanel>
		</ExpansionPanel>
	)
}

const GrayPanel = styled(ExpansionPanelSummary)`
	background-color: #f2f2f2;
	flex-direction: column;
`
const NoPadIconButton = styled(IconButton)`
	padding: 0;
`