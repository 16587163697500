import path from 'path'
import { ReceiptFile } from '../../model/ReceiptFile'
import { compressImage } from '../../utils/ImageCompressor'
import { Expense } from '../../model/Expense'

export function humanFileSize(bytes: number) {
	const thresh = 1000
	if (Math.abs(bytes) < thresh) return bytes + ' B'

	const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	let u = -1
	do {
		bytes /= thresh
		++u
	} while (Math.abs(bytes) >= thresh && u < units.length - 1)
	return bytes.toFixed(1) + ' ' + units[u]
}

async function calculateBase64(file: File): Promise<string> {
	const reader = new FileReader()
	reader.readAsDataURL(file)
	return new Promise((resolve, reject) => {
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = (error) => reject(error)
	})
}

const getExtension = (filename: string) => path.extname(filename).substr(1).toUpperCase()
const getShortName = (file: File) => {
	if (file.name.length > 20) return file.name.substr(0, 16) + '...' + getExtension(file.name)
	return file.name.substr(0, file.name.length - 4) + '.' + getExtension(file.name)
}

const isImage = (file: File) => file.type === 'image/jpeg' || file.type === 'image/png'

export const createFile = async (input: File): Promise<ReceiptFile> => {
	const file = isImage(input) ? await compressImage(input) : input
	const base64 = await calculateBase64(file)

	const extension = getExtension(file.name)
	const humanSize = humanFileSize(file.size)
	const shortName = getShortName(file)
	const type = file.type
	const name = file.name
	const size = file.size

	return { base64, extension, size, humanSize, shortName, type, name }
}

export function calculateTotalSize(expenses: Expense[]): number {
	return expenses.reduce((acc, expense) => acc + (expense.lost ? 0 : expense.file.size), 0)
}

function firstDayOfYear(year: number) {
	return new Date(year, 0, 1)
}

export function minimumDate() {
	let month = new Date().getMonth()

	//If we are in january, it is allowed
	if (month === 0) return firstDayOfYear(new Date().getFullYear() - 1)

	return firstDayOfYear(new Date().getFullYear())
}
