import React, { FocusEventHandler, useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField/TextField'
import { useAddresses } from './useAddresses'

type AddressAutocompleteFieldProps = Omit<TextFieldProps, 'onBlur' | 'onChange' | 'value'> & {
	onBlur: FocusEventHandler
	onChange: (value: string) => void
	value: string
}

export function AddressAutocompleteField(props: AddressAutocompleteFieldProps) {
	const { onBlur, onChange, value, ...textFieldProps } = props
	const [input, setInput] = useState<string>(value)
	const addresses = useAddresses(input)

	const onValueChange = (v: string) => {
		onChange(v)
		setInput(v)
	}

	return (
		<Autocomplete
			id={props.id}
			freeSolo
			value={value}
			onInputChange={(_, v) => onValueChange(v)}
			onBlur={onBlur}
			options={addresses}
			renderInput={(params) => <TextField margin={'normal'} {...textFieldProps} {...params} />}
		/>
	)
}
