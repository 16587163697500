import React from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import { Drive } from '../../model/Drive'
import { BoldTableCell, SummaryPaper, SummaryTable } from '../CommonStyledComponents'
import { useRateQuery } from '../../utils/useRateQuery'

type Row = { date: string; km: number; price: number; from: string; to: string; via?: string }

const createRows = (drives: Drive[], rate: number) =>
	drives.map((drive) => createRow(drive, rate)).filter((drive): drive is Row => !!drive)

function createRow(drive: Drive, rate: number): Row | undefined {
	if (drive.fromAddress === undefined) return undefined
	let date = moment(drive.date).format('D. MMMM')
	let from = drive.fromAddress.split(',')[0]
	let to = drive.toAddress.split(',')[0]
	let via = drive.viaAddress?.split(',')[0]
	let km = parseInt(drive.distance)
	let price = (km - 12) * rate

	return { date, from, to, via, km, price }
}

const format = (num: number) => `${num.toFixed(2)}`
const kms = (items: Row[]) => items.map(({ km }) => km).reduce((sum, i) => sum + i, 0)
const price = (items: Row[]) => items.map(({ price }) => price).reduce((sum, i) => sum + i, 0)
const totalKm = (drives: Row[]) => (drives !== undefined ? kms(drives) : 0)
const invoiceKm = (drives: Row[]) => (drives !== undefined ? kms(drives) - drives.length * 12 : 0)
const invoiceTotal = (drives: Row[]) => (drives !== undefined ? price(drives) : 0)
const replace = (number: number | string) => `${number}`.replace('.', ',')

type DriveSummaryProps = {
	drives: Drive[]
}

export function DriveSummary({ drives }: DriveSummaryProps) {
	const { data: rate } = useRateQuery()
	const rows = createRows(drives, rate!)

	return (
		<SummaryPaper>
			<SummaryTable>
				<TableHead>
					<TableRow>
						<TableCell>
							<Typography>Dato</Typography>
						</TableCell>
						<TableCell>
							<Typography>Fra</Typography>
						</TableCell>
						<TableCell>
							<Typography>Til</Typography>
						</TableCell>
						<TableCell align="right">
							<Typography>KM</Typography>
						</TableCell>
						<TableCell align="right">
							<Typography>Beløb</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row, index) => (
						<TableRow key={index}>
							<TableCell>
								<Typography>{row.date}</Typography>
							</TableCell>
							<TableCell>
								<Typography>{row.from}</Typography>
							</TableCell>
							<TableCell>
								<Typography>{row.to}</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography>{row.km} km</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography>{replace(format(row.price))} kr</Typography>
							</TableCell>
						</TableRow>
					))}
					<TableRow>
						<TableCell rowSpan={6} />
						<TableCell rowSpan={6} />
					</TableRow>
					<TableRow>
						<TableCell>
							<Typography>Total Km</Typography>
						</TableCell>
						<TableCell align="right">
							<Typography>{totalKm(rows)} km</Typography>
						</TableCell>
						<TableCell />
					</TableRow>
					<TableRow>
						<BoldTableCell>
							<Typography>Til udbetaling</Typography>
						</BoldTableCell>
						<BoldTableCell>
							<Typography align="right">{invoiceKm(rows)} km</Typography>
						</BoldTableCell>
						<BoldTableCell>
							<Typography align="right">{replace(format(invoiceTotal(rows)))} kr</Typography>
						</BoldTableCell>
					</TableRow>
				</TableBody>
			</SummaryTable>
		</SummaryPaper>
	)
}
