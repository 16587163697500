import React, { ChangeEvent, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'
import Dialog from '@material-ui/core/Dialog'
import { ThankYouAnimation } from '../ThankYouAnimation'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import ReactCodeInput from 'react-verification-code-input'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@mui/material'
import { decimalRegex, toCommandInteger, toDanishDecimal, toUsDecimal } from '../../utils/decimalHelper'
import { useRateQuery } from '../../utils/useRateQuery'

type RateFormProps = {
	activeStep: number
	isLoading: boolean
	onSendMail: () => void
	onVerification: (code: string) => void
	onRateUpdate: (rate: number) => void
}

export function RateForm(props: RateFormProps) {
	const { activeStep, isLoading, onSendMail, onRateUpdate, onVerification } = props
	const { data: initialRate } = useRateQuery()

	const [code, setCode] = useState('')
	const [rate, setRate] = useState<string>()

	useEffect(() => {
		if (initialRate && !rate) setRate(toDanishDecimal(initialRate.toString()))
	}, [initialRate])

	function shouldDisableNextButton() {
		if (activeStep === 0) return false
		if (activeStep === 1) return code.length !== 6
		if (activeStep === 2) return rate === undefined
	}

	function onClick() {
		switch (activeStep) {
			case 0:
				onSendMail()
				break
			case 1:
				if (code) onVerification(code)
				console.warn("Code wasn't set when clicking login button")
				break
			case 2:
				if (rate && !Number.isNaN(toCommandInteger(rate))) onRateUpdate(toCommandInteger(rate))
				break
			default:
				break
		}
	}

	return (
		<>
			<MainContainer>
				{activeStep === 0 && <IntroductionStep />}
				{activeStep === 1 && <VerificationStep code={code} onCodeChange={setCode} />}
				{activeStep === 2 && <UpdateStep onRateChange={setRate} rate={rate} />}
				{activeStep === 3 && <ThankYouStep />}
			</MainContainer>
			<ButtonContainer>
				<Button
					color={'primary'}
					size="large"
					variant={'contained'}
					style={{ color: 'white', height: 50 }}
					onClick={onClick}
					disabled={isLoading || shouldDisableNextButton()}
				>
					{isLoading ? (
						<CircularProgress style={{ color: '#ed7303' }} />
					) : (
						<>
							{activeStep === 0 && 'Send mail'}
							{activeStep === 1 && 'Login'}
							{activeStep === 2 && 'Opdater'}
						</>
					)}
				</Button>
			</ButtonContainer>
		</>
	)
}

function IntroductionStep() {
	return (
		<Typography variant="body1">
			For at kunne opdatere satsen skal du først vise, at du har adgang til bogholderiets mail-adresse. Ved at klikke på
			knappen nedenfor vil der blive sendt en mail til bogholderiet, som indeholder en kode, du skal indtaste på næste
			side.
		</Typography>
	)
}

type VerificationStepProps = {
	code: string
	onCodeChange: (code: string) => void
}
function VerificationStep(props: VerificationStepProps) {
	const { code, onCodeChange } = props

	return <StyledCodeInput values={code?.toString().split('')} onChange={onCodeChange} />
}

type UpdateStepProps = {
	rate: string | undefined
	onRateChange: (rate: string | undefined) => void
}
function UpdateStep(props: UpdateStepProps) {
	const { rate, onRateChange } = props
	const suspiciousRate = !!(rate && parseInt(rate) > 10)

	function handleDecimalInput(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
		const value = e.target.value

		if (value.match(decimalRegex)) {
			const replace = toUsDecimal(value)
			onRateChange(replace)
		} else if (!value) {
			onRateChange(undefined)
		}
	}

	return (
		<TextField
			required
			label="Ny sats"
			fullWidth
			margin="normal"
			variant="standard"
			InputProps={{ endAdornment: <InputAdornment position="start">Kr pr. Km</InputAdornment> }}
			onChange={handleDecimalInput}
			value={toDanishDecimal(rate ?? '')}
			helperText={suspiciousRate ? 'Satsen virker meget høj. Er du sikker?' : ' '}
			error={suspiciousRate}
		/>
	)
}

function ThankYouStep() {
	return (
		<Dialog fullWidth={true} maxWidth={'md'} open aria-labelledby="max-width-dialog-title" id="thank-you-dialog">
			<ThankYouAnimation />
			<Title variant={'h4'}>Sats opdateret</Title>
			<DialogContent>
				<Text variant={'body1'}>Kørselssatsen er nu opdateret, og du behøver ikke foretage dig yderligere.</Text>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => window.location.reload()} color="primary" id="thank-you-ok-button">
					OK
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const StyledCodeInput = styled(ReactCodeInput)`
	& input {
		font-family: Inter, sans-serif;
		font-size: 24px;
		&:focus {
			outline: none;
			border-color: #ed7303;
			caret-color: #ed7303;
		}
	}
`

const Title = styled(Typography)`
	text-align: center;
	font-size: 2em !important;
	padding-top: 0;
	padding-bottom: 1rem;
`
const Text = styled(Typography)`
	text-align: center;
`
const ButtonContainer = styled.div`
	width: 100%;
	margin-top: 16px;
	display: flex;
	justify-content: center;
`
const MainContainer = styled(Grid)`
	display: flex;
	flex-direction: column;
	align-items: center;
`
