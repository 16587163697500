import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import Dog from '../assets/animations/css/Dog'
import { BoldTypography, PaddedPaper } from './CommonStyledComponents'
import React from 'react'
import styled from 'styled-components'

export function LoadingPage() {
	return (
		<PaddedPaper>
			<CenteringContainer>
				<MinimumWidth>
					<TextContainer>
						<BoldTypography variant="h6" gutterBottom>
							Sender din data
						</BoldTypography>
						<Typography variant="body1" gutterBottom>
							Vi sender din anmodning til behandling
						</Typography>
					</TextContainer>
					<PadLeft>
						<FadeAnimation in={true} unmountOnExit>
							<Dog />
						</FadeAnimation>
					</PadLeft>
				</MinimumWidth>
			</CenteringContainer>
		</PaddedPaper>
	)
}

const CenteringContainer = styled.div`
	display: flex;
	justify-content: center;
`
const MinimumWidth = styled.div`
	width: 380px;
`
const TextContainer = styled.div`
	margin-bottom: 100px;
	text-align: center;
`
const PadLeft = styled.div`
	padding-left: 10px;
`
const FadeAnimation = styled(Fade)`
	transition-delay: 800ms;
`
