import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import React from 'react'

type DeleteDriveDialogProps = {
	open: boolean
	onClose: () => void
	onAccepted: () => void
}
export function DeleteDriveDialog({ open, onClose, onAccepted }: DeleteDriveDialogProps) {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{'Vil du slette kørslen?'}</DialogTitle>
			<DialogContent>
				<DialogContentText />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Annuller</Button>
				<Button onClick={onAccepted} color="primary" autoFocus>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	)
}
