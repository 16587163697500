import React from 'react'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { ReceiptDialog } from './ReceiptDialog'
import pdfPlaceholder from './assets/pdfPlaceholder.png'
import { ReceiptFile } from '../../model/ReceiptFile'
import { ConstraintCard, SmallMediaCard } from '../CommonStyledComponents'
import Button from '@material-ui/core/Button'

type ReceiptCardProps = {
	file: ReceiptFile
	receiptName: string
}

export function ReceiptCard({ receiptName, file }: ReceiptCardProps) {
	const { name, humanSize, base64, extension } = file

	const [open, setOpen] = React.useState(false)
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)

	return (
		<ConstraintCard>
			<SmallMediaCard image={extension === 'PDF' ? pdfPlaceholder : base64} />
			<CardContent>
				<Typography gutterBottom variant="h5" component="h2">
					{receiptName}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					- Filnavn: {name}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					- Filtype: {extension}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					- Størrelse: {humanSize}
				</Typography>
			</CardContent>
			<CardActions>
				<ReceiptDialog open={open} onClose={handleClose} receiptName={receiptName} file={file} />
				<Button onClick={handleOpen} size="small" color="primary">
					Vis kvittering
				</Button>
			</CardActions>
		</ConstraintCard>
	)
}
