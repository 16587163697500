import React from 'react'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import lostPlaceholder from './assets/unknown.png'
import { LostReceiptDialog } from './LostReceiptDialog'
import { LostReceipt } from '../../model/LostReceipt'
import { ConstraintCard, SmallMediaCard } from '../CommonStyledComponents'

type LostReceiptCardProps = {
	lostReceipt: LostReceipt
	receiptName: string
	onSave: (lostReceipt: LostReceipt) => void
}

export function LostReceiptCard({ lostReceipt, receiptName, onSave }: LostReceiptCardProps) {
	return (
		<ConstraintCard>
			<SmallMediaCard image={lostPlaceholder} />
			<CardContent>
				<Typography gutterBottom variant="h5" component="h2">
					{receiptName}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					- Status: Mistet
				</Typography>
				<Typography id={'descriptionText'} variant="body2" color="textSecondary" component="p">
					- Beskrivelse: {lostReceipt.description}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					- Årsag: {lostReceipt.reason}
				</Typography>
			</CardContent>
			<CardActions>
				<LostReceiptDialog initialState={lostReceipt} onSubmit={onSave} />
			</CardActions>
		</ConstraintCard>
	)
}
