import { AddressResponse } from '../model/AddressResponse'
import { useEffect, useState } from 'react'
import { getDistance } from './RouteService'
import { Coordinates } from '../model/Coordinates'

const round = (n: number) => Math.ceil(parseFloat(`${n}`))
async function getCoordinates(address: string): Promise<Coordinates | undefined> {
	const url = 'https://dawa.aws.dk/adresser/autocomplete?q=' + address + '&per_side=1'

	const response = await fetch(url)
	const addressResponse = (await response.json()) as AddressResponse[]
	if (!addressResponse || addressResponse.length === 0) return
	const { x, y } = addressResponse[0].adresse

	return { x, y }
}

export function useCoordinates(address?: string) {
	const [coordinates, setCoordinates] = useState<Coordinates>()
	useEffect(() => {
		if (address) {
			try {
				getCoordinates(address).then(setCoordinates)
			} catch (e) {
				console.log(`failed to fetch coordinates for: ${address}`)
			}
		}
	}, [address])
	return coordinates
}

export function useDistance(from?: string, to?: string, via?: string, fetch?: unknown) {
	const [distance, setDistance] = useState<number | undefined>()
	const fromCoordinates = useCoordinates(from)
	const toCoordinates = useCoordinates(to)
	const viaCoordinates = useCoordinates(via)

	async function calculateDistance() {
		if (fromCoordinates && toCoordinates) {
			if (!viaCoordinates) {
				return await getDistance(fromCoordinates, toCoordinates)
			} else {
				const fromVia = await getDistance(fromCoordinates, viaCoordinates)
				const viaTo = await getDistance(viaCoordinates, toCoordinates)
				return fromVia + viaTo
			}
		}
	}
	useEffect(() => {
		try {
			calculateDistance().then((d) => d && setDistance(d))
		} catch (e) {
			console.log(`failed to fetch distance for: ${from}, ${to}, ${via}`)
		}
	}, [fetch])

	return distance ? round(distance) : undefined
}
