import { Lottie } from '@crello/react-lottie'
import MailFlyingLottie from '../assets/animations/lottie/mail-flying.json'
import DoneLottie from '../assets/animations/lottie/done.json'
import React, { useState } from 'react'
import styled from 'styled-components'

const MailFlyingAnimation = ({ onComplete }: { onComplete: () => void }) => (
	<FlyingAnimationContainer>
		<Lottie
			config={{ animationData: MailFlyingLottie, loop: false }}
			lottieEventListeners={[{ name: 'complete', callback: onComplete }]}
		/>
	</FlyingAnimationContainer>
)

const DoneAnimation = () => (
	<DoneAnimationContainer>
		<Lottie config={{ animationData: DoneLottie, loop: false }} />
	</DoneAnimationContainer>
)

export function ThankYouAnimation() {
	const [initialAnimationDone, setInitialAnimationDone] = useState(false)

	const onComplete = () => setInitialAnimationDone(true)

	return (
		<AnimationContainer>
			{!initialAnimationDone ? <MailFlyingAnimation onComplete={onComplete} /> : <DoneAnimation />}
		</AnimationContainer>
	)
}

const AnimationContainer = styled.div`
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
`
const DoneAnimationContainer = styled.div`
	width: 40%;
	min-width: 300px
`
const FlyingAnimationContainer = styled.div`
	height: 115%;
`
