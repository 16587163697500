export const drivePurposes = [
	{ id: 1, name: 'Divisionsaften' },
	{ id: 2, name: 'Møde: Intern Deltager' },
	{ id: 3, name: 'Møde: Ekstern Deltager' },
	{ id: 4, name: 'Opstart' },
	{ id: 5, name: 'Planlægningsdag' },
	{ id: 6, name: 'Teambuilding' },
]

export const expensePurposes = [
	{ id: 7, name: 'Forplejning' },
	{ id: 19, name: 'Parkering' },
	{ id: 13, name: 'Transport' },
	{ id: 21, name: 'Småanskaffelser' },
	{ id: 22, name: 'Kontorartikler' },
	{ id: 23, name: 'Diverse' },
]

/**
 * All "Forplejning" purposes require a participant list
 * @param id
 * @returns {boolean|boolean}
 */
export function requiresParticipantList(id: number | string) {
	const idNumber = typeof id === 'string' ? parseInt(id) : id
	return idNumber === 7
}

export function getPurposeById(id: number | string) {
	const purposes = drivePurposes.concat(expensePurposes).filter((p) => p.id === id)
	if (purposes && purposes.length === 1) return purposes[0].name

	throw new Error(`Unknown id: ${id}`)
}
