import React from 'react'
import 'typeface-noto-sans'
import Typography from '@material-ui/core/Typography'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import styled from 'styled-components'
import { BoldTypography, PaddedPaper } from './CommonStyledComponents'
import { Reimbursement } from '../model/Reimbursement'
import { useRateQuery } from '../utils/useRateQuery'

function renderDriveTerms(rate?: number) {
	const rateString = rate?.toString().replace('.', ',')
	const currentYear = new Date().getFullYear()

	return (
		<>
			<BoldTypography variant="subtitle1" gutterBottom>
				1. Regler for kørsel
			</BoldTypography>
			<Typography variant="body1" gutterBottom>
				Brug offentlige transportmidler så ofte som muligt. Hvis du vælger at køre i din egen bil, kan du få refunderet
				kr. {rateString} pr. km. bortset fra de første 12 km på udturen, og de første 12 km på hjemturen.
			</Typography>
			<BoldTypography variant="subtitle1">2. Deadline</BoldTypography>
			<Typography variant="body1" gutterBottom>
				Der er deadline for indlevering af kørsel 10. december {currentYear}. Kørsel foretaget efter 10. december skal
				være sendt til bogholderiet senest 7. januar {currentYear + 1} - ellers gives der ikke refusion, og det er
				uagtet festival år.
			</Typography>
			<BoldTypography variant="subtitle1">3. Hvad kan vi ikke godtgøre?</BoldTypography>
			<Typography component={'span'} variant="body1" gutterBottom>
				Vi kan ikke refundere kørsel til aktiviteter, der ikke er direkte forbundet med den frivillige opgave, man har
				på festivalen, med mindre man har en praktisk opgave herved. Eksempelvis kan nævnes:
				<ul>
					<li>Fællesmøder</li>
					<li>Leadership Lab</li>
					<li>Årsfest</li>
					<li>Vinterfest</li>
					<li>Generalforsamling</li>
					<li>Sociale arrangementer i divisionerne</li>
					<li>Inspirationsbesøg</li>
				</ul>
				<i>
					Herudover refunderes kørsel ikke i festivalperioden, gældende fra 1 uge før afvikling, i afviklingsperioden,
					samt 1 uge efter afvikling, dvs. i perioden hvor der stilles overnatning til rådighed.
				</i>
				<br />
			</Typography>
		</>
	)
}
function renderExpenseTerms() {
	return (
		<>
			<BoldTypography variant="subtitle1" gutterBottom>
				1. Hvilken organisation?
			</BoldTypography>
			<Typography variant="body1" gutterBottom>
				Du skal starte med at finde ud af om, udgiften hører hjemme i Roskilde Festival eller Fonden Roskilde Festival.
				Spørg din divisions budgetansvarlige, hvis du er i tvivl. Vær opmærksom på at flere selskaber og flere
				budgetnumre/afdelinger <i>ikke</i> kan kombineres.
			</Typography>
			<BoldTypography variant="subtitle1">2. Hvad refunderes?</BoldTypography>
			<Typography variant="body1" gutterBottom>
				Generelt set får man refunderet de samme udgifter her, som hvis man var aktiv i en sportsforening. Her afholder
				man selv udgifter til udstyr, kørsel osv., og man kan ikke forvente 100% refusion af alle potentielle udgifter.
				Principielt kan man dog sige, at Roskilde Festival ikke ønsker, at frivillige bruger penge på at være frivillige
				– derfor er der i begrænset omfang mulighed for transportrefusion og dækning, hvis man har ekstraordinære
				telefonudgifter, der skyldes arbejdet med Roskilde Festival.
			</Typography>
			<BoldTypography variant="subtitle1">3. Hvilke deadlines?</BoldTypography>
			<Typography component={'span'} variant="body1" gutterBottom>
				Udlæggene skal dokumenteres af kvitteringer, hvor det tydeligt fremgår, hvad der er købt. Man skal afregne udlæg
				minimum en gang i kvartalet. Udlæg fra 4. kvartal (dvs. oktober—december), skal af regnskabsmæssige hensyn
				afregnes senest ved udgangen af januar måned.
			</Typography>
			<BoldTypography variant="subtitle1">4. Hvem skal godkende?</BoldTypography>
			<Typography variant="body1" gutterBottom>
				Der kan <i>kun</i> refunderes udgifter, hvis disse optræder i budgettet. Man skal derfor lave et estimat på
				udgifterne ved begyndelsen af hvert festivalår. Udgifter til refusion af telefonomkostninger og transport skal
				altid godkendes af budgetansvarlige. Ved omkostninger der er højere end <i>normalt forventet</i>, skal det
				godkendes af en divisionschef.
			</Typography>
		</>
	)
}

type TermsPageProps = {
	accepted: boolean
	onChange: (accepted: boolean) => void
	type: Reimbursement
}

const getReimbursementText = (type: Reimbursement): string => (type === 'drive' ? 'kørsel' : 'udlæg')

export function TermsPage({ accepted, onChange, type }: TermsPageProps) {
	const { data: rate } = useRateQuery()

	const reimbursementText = `${getReimbursementText(type)}sgodtgørelse`

	return (
		<PaddedPaper>
			<div style={{ marginBottom: 24 }}>
				<Typography variant="h6" gutterBottom color="secondary">
					Vi starter med det kedelige: Regler.
				</Typography>
				<Typography variant="body1" gutterBottom>
					Læs venligst reglerne for {reimbursementText}
				</Typography>
			</div>
			<TermsBox>{type === 'drive' ? renderDriveTerms(rate) : renderExpenseTerms()}</TermsBox>

			<FormGroup row>
				<FormControlLabel
					id={'terms-checkbox'}
					control={
						<Checkbox checked={accepted} onChange={({ target: { checked } }) => onChange(checked)} value="check" />
					}
					label={'Jeg har læst og forstået reglerne for ' + reimbursementText + '.'}
				/>
			</FormGroup>
		</PaddedPaper>
	)
}

const TermsBox = styled.div`
	height: 330px;
	padding: 16px;
	border: 2px solid #ccc;
	border-radius: 3px;
	overflow-y: scroll;
`
