import React, { ReactNode, useState } from 'react'

export type AddressContextProps = {
	addresses: string[]
}

type AddressContextType = AddressContextProps & {
	addAddress: (address: string) => void
}
const defaultAddresses = ['Havsteensvej 11, 4000 Roskilde', 'Gammel Kongevej 11, 1610 København V']

const AddressContext = React.createContext<AddressContextType>({ addresses: defaultAddresses, addAddress: () => {} })
export function AddressContextProvider(props: { children: ReactNode }): JSX.Element {
	const [addresses, setAddresses] = useState(defaultAddresses)
	const addAddress = (address: string) => {
		if (!addresses.includes(address)) setAddresses((prevState) => [...prevState, address].sort())
	}
	return <AddressContext.Provider value={{ addresses, addAddress }}>{props.children}</AddressContext.Provider>
}

export const AddressContextConsumer = AddressContext.Consumer

export function useAddressContext(): AddressContextType {
	const context = React.useContext(AddressContext)
	if (context === undefined) {
		throw new Error(`${useAddressContext.name} must be used within a AddressContextProvider`)
	}
	return context
}
