import { ThemeProvider } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import React, { useEffect } from 'react'
import { LandingPage } from './LandingPage'
import { RFTheme } from './components/RFTheme'
import styled from 'styled-components'
import { AddressContextProvider } from './utils/AddressContext'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarProvider } from './utils/SnackbarProvider'
import { QueryClient, QueryClientProvider } from 'react-query'
import { matomoInstance } from './utils/MatomoHelper'
import { MatomoProvider } from '@datapunt/matomo-tracker-react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { AdminPage } from './components/Rate/AdminPage'
import { auto } from 'darkreader'
import { useMediaQuery } from '@mui/material'
const backgroundShape = require('./assets/images/background.svg')

// This does something with decimal numbers (I am pretty sure)
const numeral = require('numeral')
numeral.defaultFormat('0')

const queryClient = new QueryClient()

const router = createBrowserRouter([
	{
		path: '*',
		element: <LandingPage />,
	},
	{
		path: '/admin',
		element: <AdminPage />,
	},
])

function followSystemColorScheme() {
	auto({
		brightness: 100,
		contrast: 90,
		sepia: 10,
	})
}
followSystemColorScheme()

export function App() {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
	useEffect(() => followSystemColorScheme(), [prefersDarkMode])

	return (
		<MatomoProvider value={matomoInstance}>
			<ThemeProvider theme={RFTheme}>
				<CssBaseline />
				<RootContainer>
					<MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale('da')}>
						<AddressContextProvider>
							<SnackbarProvider>
								<QueryClientProvider client={queryClient}>
									<RouterProvider router={router} />
								</QueryClientProvider>
							</SnackbarProvider>
						</AddressContextProvider>
					</MuiPickersUtilsProvider>
				</RootContainer>
			</ThemeProvider>
		</MatomoProvider>
	)
}

const RootContainer = styled.div`
	flex-grow: 1;
	background-position: 0 400px;
	background-image: url(${backgroundShape});
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	min-height: ${window.innerHeight}px;
	padding-bottom: 32px;
`
