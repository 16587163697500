import { useMutation } from 'react-query'
import { StatusCodeError } from '../utils/statusCodeError'

export function useVerificationMutation(
	onSuccess?: () => void,
	onError?: (error: StatusCodeError) => void,
	onSettled?: () => void
) {
	return useMutation(
		async (code: number) => {
			const { ok, status } = await fetch(`api/api/admin/verify`, {
				method: 'POST',
				body: JSON.stringify({ authenticationCode: code }),
				headers: { 'Content-Type': 'application/json' },
			})
			if (!ok) throw new StatusCodeError(status)
		},
		{
			onSuccess: () => onSuccess && onSuccess(),
			onError: (error) => onError && onError(error as StatusCodeError),
			onSettled: () => onSettled && onSettled(),
		}
	)
}
