import { RouteServiceResponse } from '../model/RouteServiceResponse'
import { Coordinates } from '../model/Coordinates'

const API_KEY = '5b3ce3597851110001cf6248975cfc9f992040a7bba8bab6b0ec81dd'

const routeServiceUrl = (from: string, to: string) =>
	`https://api.openrouteservice.org/v2/directions/driving-car?api_key=${API_KEY}&start=${from}&end=${to}`

export async function getDistance(from: Coordinates, to: Coordinates) {
	const url = routeServiceUrl(`${from.x},${from.y}`, `${to.x},${to.y}`)
	const response = await fetch(url)
	const routeServiceResponse = (await response.json()) as RouteServiceResponse

	let distance
	try {
		distance = routeServiceResponse.features[0].properties.summary.distance / 1000
	} catch (e) {
		distance = 0
		console.error(e)
	}

	if (distance.toString() === 'NaN') return 0

	return distance
}
