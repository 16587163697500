import { setGlobal } from 'reactn'
import { defaultDrive } from './Schemes'
import { Drive } from '../model/Drive'

export const deleteDrive = (identity: number, drives: Drive[]) => {
	return drives.filter((x) => x.identity !== identity)
}

export const saveDrive = (
	identity: number,
	value: Drive,
	drives: Drive[],
	createReverse: boolean,
	newIdentity: number
) => {
	const state = [...drives]

	let index = state.findIndex((x) => x.identity === identity)

	if (createReverse && !value.saved) {
		let newValue = JSON.parse(JSON.stringify(value))
		newValue.fromAddress = value.toAddress
		newValue.toAddress = value.fromAddress
		newValue.identity = newIdentity

		value.saved = true
		newValue.saved = true
		setGlobal({ currentlyExpanded: newIdentity })

		state.push(newValue)
	}

	state[index] = value

	return state
}

export const createNewDrive = (identity: number, drives: Drive[]) => {
	const state = [...drives]
	state.push(defaultDrive(identity))
	setGlobal({ currentlyExpanded: identity })
	return state
}

export const changeDrive = (identity: number, value: Drive, drives: Drive[]) => {
	const state = [...drives]
	let index = state.findIndex((x) => x.identity === identity)

	state[index] = value

	return state
}

function addAfter(array: Drive[], index: number, newItem: Drive) {
	return [...array.slice(0, index), newItem, ...array.slice(index)]
}

export const duplicateDrive = (toBeDuplicated: Drive, identity: number, drives: Drive[], newID: number) => {
	//Evil method to copy object without link.
	const newValue = JSON.parse(JSON.stringify(toBeDuplicated))

	//Setting up new drive
	newValue.identity = newID
	newValue.trySubmit = false
	newValue.validState = false

	//Index where the copy should be made.
	let index = drives.findIndex((x) => x.identity === identity) + 1

	//Adding at the right point
	return addAfter([...drives], index, newValue)
}
