import React from 'react'
import { Lottie } from '@crello/react-lottie'
import cloudLottie from './assets/cloud.json'

export function CloudStopped() {
	return <Lottie config={{ animationData: cloudLottie, loop: false }} />
}

export function CloudPlaying() {
	return <Lottie config={{ animationData: cloudLottie, loop: true }} />
}
