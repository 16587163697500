import { driveSchema, personalSchema } from './Schemes'
import { expenseSchema } from './Schemes'
import { Drive } from '../model/Drive'
import { Expense } from '../model/Expense'
import { PersonalInput } from '../model/PersonalInput'

/**
 * Validates a given drive
 * @param d
 * @returns {boolean|*}
 */
export const validateDrive = (d: Drive) => driveSchema.isValidSync(d)

/**
 * Validates a given expense
 * @param e
 * @returns {boolean|*}
 */
export const validateExpense = (e: Expense) => expenseSchema.isValidSync(e)

/**
 * Validates personal information
 * @param p
 * @returns {boolean|*}
 */
export const validatePersonalData = (p: PersonalInput) => personalSchema.isValidSync(p)

/**
 * Checks all drives
 * @param drives
 * @returns {boolean}
 */
export function validateDrives(drives: Drive[]) {
	if (drives.length === 0) return false
	if (drives.length < 1) drives = drives.filter((d) => d.valid)
	let errors = drives.filter((d) => !validateDrive(d)).length
	return errors === 0
}

/**
 * Checks all expenses
 * @param expenses
 * @returns {boolean}
 */
export function validateExpenses(expenses: Expense[]) {
	if (expenses.length === 0) return false
	let errors = expenses.filter((e) => !validateExpense(e)).length
	return errors === 0
}
