import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import { RFTheme } from './RFTheme'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

export const PaddedPaper = styled(Paper)`
	padding: ${RFTheme.spacing(3)}px;
`
export const BoldTypography = styled(Typography)`
	font-weight: bold;
`
export const ConstraintCard = styled(Card)`
	max-width: 345px;
	width: 100%;
`
export const SmallMediaCard = styled(CardMedia)`
	height: 140px;
`
export const BoldTableCell = styled(TableCell)`
	font-weight: bold;
`
export const SummaryPaper = styled(Paper)`
	margin-top: 24px;
	overflow-x: auto;
`
export const SummaryTable = styled(Table)`
	min-width: 700px;
`
export const HeaderContainer = styled.div`
	flex-basis: 48%;
	text-align: center;
`
export const Header = styled(Typography)<{ bold?: boolean }>`
	flex-basis: 50%;
	overflow-y: hidden;
	${({ bold }) => bold && 'font-weight: bold;'}
`
export const DetailsPanel = styled(ExpansionPanelDetails)`
	align-items: center;
`
export const Gab = styled.div`
	padding-top: 20px;
`