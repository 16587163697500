import { createMuiTheme } from '@material-ui/core'

export const RFTheme = createMuiTheme({
	palette: {
		secondary: {
			main: '#ed7303',
		},
		primary: {
			main: '#ed7303',
		},
	},
	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: ['"Lato"', 'sans-serif'].join(','),
	},
	overrides: {
		// @ts-ignore
		MuiPickersDay: {
			day: {
				color: '#ed7303',
			},
			daySelected: {
				backgroundColor: '#ed7303',
				color: 'white',
			},
			current: {
				color: '#ed7303',
			},
		},
		MuiStepIcon: {
			text: {
				fill: '#FFF',
			},
		},
		MuiFormLabel: {
			root: {
				'&$focused': {
					color: '#ed7303',
				},
			},
		},
		MuiInput: {
			underline: {
				'&:after': {
					borderBottom: `2px solid #ed7303`,
				},
			},
		},
	},
})
