import React, { useEffect, useState } from 'react'
import 'typeface-noto-sans'
import { ReceiptDropzone } from './ReceiptDropzone'
import update from 'react-addons-update'
import { Expense } from '../../model/Expense'
import { ReceiptFile } from '../../model/ReceiptFile'
import { LostReceipt } from '../../model/LostReceipt'
import { ExpenseInput } from './ExpenseInput'
import styled from 'styled-components'
import { defaultExpense, defaultLostExpense } from '../../utils/DefaultValues'
import { Gab } from '../CommonStyledComponents'
import { useSnackbar } from 'notistack'
import { Button } from '@material-ui/core'
import { calculateTotalSize } from './ExpenseUtils'
import { useMatomo } from '@datapunt/matomo-tracker-react'

const COMPRESS_URL = 'https://www.adobe.com/dk/acrobat/online/compress-pdf.html'

type ExpensePageProps = {
	onChange: (expenses: Expense[]) => void
	value: Expense[]
}

const MAX_SIZE = 15 * 1024 * 1024

export function ExpensePage({ onChange, value }: ExpensePageProps) {
	const [expenses, setExpenses] = useState<Expense[]>(value)
	const [expanded, setExpanded] = useState(1)
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const { trackEvent } = useMatomo()

	useEffect(() => onChange(expenses), [expenses])

	function maxSizeReached() {
		const key = enqueueSnackbar(
			`Max størrelse på udlæg overskrevet (15 MB). Har du flere udgifter, skal du afslutte nuværende udlæg og oprette et nyt.`,
			{
				variant: 'error',
				persist: true,
				preventDuplicate: true,
			}
		)
		trackEvent({ category: 'Expense', action: 'Max size reached', value: expenses.length })
		setTimeout(() => closeSnackbar(key), 8000)
	}

	function fileAdded(file: ReceiptFile) {
		setExpenses((previousExpenses) => {
			if (calculateTotalSize(previousExpenses) + file.size < MAX_SIZE)
				return previousExpenses.concat(defaultExpense(file))
			maxSizeReached()
			return previousExpenses
		})
	}

	function lostReceiptAdded(lostReceipt: LostReceipt) {
		setExpenses((previousExpenses) => previousExpenses.concat(defaultLostExpense(lostReceipt)))
		setExpanded(expenses.length + 1)
	}

	function dropComplete(batchSize: number) {
		setExpanded(expenses.length + batchSize)
	}

	function expenseChanged(id: number, value: Expense) {
		let index = id - 1
		setExpenses(update(expenses, { [index]: { $set: value } }))
	}

	function deleteExpense(id: number) {
		setExpenses(expenses.filter((r) => id - 1 !== expenses.indexOf(r)))
	}

	function fileRejected(file: File) {
		enqueueSnackbar(`'${file?.name}' er ikke understøttet`, { variant: 'error' })
	}

	function fileTooLarge(file: File) {
		const key = enqueueSnackbar(`'${file?.name}' er for stor (5 MB)`, {
			variant: 'error',
			persist: true,
			action: <CompressButton />,
		})
		setTimeout(() => closeSnackbar(key), 8000)
	}

	const handleExpanded = (id: number) => {
		if (expanded === id) setExpanded(-1)
		else setExpanded(id)
	}

	return (
		<Main>
			{expenses.map((expense, i) => {
				const id = i + 1
				return (
					<ExpenseInput
						key={id}
						id={id}
						state={expense}
						onChange={expenseChanged}
						onDelete={deleteExpense}
						expanded={expanded === id}
						toggleExpanded={handleExpanded}
					/>
				)
			})}
			<Gab />
			<ReceiptDropzone
				onFileAdded={fileAdded}
				onLostReceiptAdded={lostReceiptAdded}
				onDropComplete={dropComplete}
				onFileTooLarge={fileTooLarge}
				onFileRejected={fileRejected}
				compact={expenses.length !== 0}
			/>
		</Main>
	)
}

function CompressButton() {
	const onClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault()
		window.open(COMPRESS_URL, '_blank')
	}
	return (
		<ToastActionButton size="small" onClick={onClick} href={COMPRESS_URL}>
			Komprimér
		</ToastActionButton>
	)
}

const Main = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`
const ToastActionButton = styled(Button)`
	color: white;
	font-family: Open Sans, sans-serif;
	font-size: 0.875rem;
	line-height: 1.75;
	text-transform: initial;
	& {
		font-weight: bold;
	}
`
