import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PDFPreview from './PDFPreview'
import { ReceiptFile } from '../../model/ReceiptFile'
import styled from 'styled-components'

type ReceiptDialogProps = {
	file: ReceiptFile
	receiptName: string
	open: boolean
	onClose: () => void
}

export function ReceiptDialog({ receiptName, file, open, onClose }: ReceiptDialogProps) {
	const { extension, base64 } = file

	return (
		<>
			<Dialog fullWidth maxWidth={'md'} open={open} onClose={onClose}>
				<CenteredTitle>{receiptName}</CenteredTitle>
				<CenteredContent>
					{extension === 'PDF' ? <PDFPreview file={base64} /> : <FullWidthImage src={base64} />}
				</CenteredContent>
				<DialogActions>
					<Button
						onClick={(e) => {
							e.stopPropagation()
							onClose()
						}}
						color="primary"
					>
						Luk
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

const CenteredTitle = styled(DialogTitle)`
	display: flex;
	justify-content: center;
`
const CenteredContent = styled(DialogContent)`
	display: flex;
	justify-content: center;
`
const FullWidthImage = styled.img`
	width: 100%;
	object-fit: contain;
`
