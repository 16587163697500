import { useQuery } from 'react-query'

export function useRateQuery() {
	return useQuery(
		'rate',
		async () => {
			const response = await fetch(`api/api/rate`)

			if (!response.ok) throw new Error(response.statusText)

			const rateInOere = (await response.json()) as number
			// Converting to DKK
			return Number((rateInOere / 100).toFixed(2))
		},
		{
			refetchOnWindowFocus: false,
		}
	)
}
