import { useMutation } from 'react-query'
import { StatusCodeError } from '../utils/statusCodeError'
import { ChangeRateCommand } from '../dtos/ChangeRateCommand'

export function useUpdateRateMutation(
	onSuccess?: () => void,
	onError?: (error: StatusCodeError) => void,
	onSettled?: () => void
) {
	return useMutation(
		async (command: ChangeRateCommand) => {
			const { ok, status } = await fetch(`api/api/admin/update-rate`, {
				method: 'POST',
				body: JSON.stringify(command),
				headers: { 'Content-Type': 'application/json' },
			})
			if (!ok) throw new StatusCodeError(status)
		},
		{
			onSuccess: () => onSuccess && onSuccess(),
			onError: (error) => onError && onError(error as StatusCodeError),
			onSettled: () => onSettled && onSettled(),
		}
	)
}
