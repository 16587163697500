import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import React from 'react'

type DeleteButtonProps = {
	onApproved: () => void
}

export function DeleteButton({ onApproved }: DeleteButtonProps) {
	const [dialogOpen, setDialogOpen] = React.useState(false)

	const openDeleteDialog = () => setDialogOpen(true)
	const closeDeleteDialog = () => setDialogOpen(false)

	const onClick = () => {
		closeDeleteDialog()
		onApproved()
	}

	return (
		<span>
			<Button size="small" onClick={openDeleteDialog}>
				Slet
			</Button>
			<Dialog open={dialogOpen} onClose={closeDeleteDialog}>
				<DialogTitle>{'Vil du slette udgiften?'}</DialogTitle>
				<DialogContent>
					<DialogContentText />
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDeleteDialog}>Annuller</Button>
					<Button onClick={onClick} color="primary" autoFocus>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</span>
	)
}
