import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Lottie } from '@crello/react-lottie'
import fireLottie from '../assets/animations/lottie/fire.json'
import styled from 'styled-components'

export const Fire = () => <Lottie config={{ animationData: fireLottie, loop: true }} />

const DEFAULT_TIMER = 10

type ErrorDialogProps = {
	state: boolean
	onRetry: () => void
}

export function ErrorDialog({ state, onRetry }: ErrorDialogProps) {
	const [open, setOpen] = React.useState(false)
	const [timer, setTimer] = React.useState(DEFAULT_TIMER)
	const [countdown, setCountdown] = React.useState<NodeJS.Timeout>()

	useEffect(() => {
		setOpen(state)
		if (state && timer > 0) setCountdown(setInterval(() => setTimer((t) => t - 1), 1000))
	}, [state])

	useEffect(() => {
		if (timer === 0 && countdown) clearInterval(countdown)
	}, [timer, countdown])

	const retry = () => {
		setTimer(DEFAULT_TIMER)
		onRetry()
	}

	return (
		<Dialog fullWidth={true} maxWidth={'md'} open={open}>
			<AnimationContainer>
				<Fire />
			</AnimationContainer>
			<Title>Beklager, men der er ild i et eller andet!</Title>
			<Content>
				<Text>
					Prøv igen eller kontakt os via{' '}
					<Link href={'mailto:bogholderi@roskilde-festival.dk?subject=Godtgørelsesfejl'}>mail</Link>.
				</Text>
				<RetryButton disabled={timer !== 0} variant="contained" color="primary" onClick={retry}>
					{timer > 0 ? `Vent ${timer} sekunder` : 'Prøv igen'}
				</RetryButton>
			</Content>
		</Dialog>
	)
}

const AnimationContainer = styled.div`
	height: 200px;
	padding-top: 20px;
`
const Title = styled(DialogTitle)`
	text-align: center;
	color: #000;
`
const Text = styled(DialogContentText)`
	text-align: center;
	color: #000;
`
const Content = styled(DialogContent)`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding-bottom: 12px; //This removes the stupid scrollbar
`
const RetryButton = styled(Button)`
	color: white;
	margin: 10px 30%;
`
const Link = styled.a`
	color: #ed7303;
`
