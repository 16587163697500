import React, { useEffect, useState } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import { useFormik } from 'formik'
import { driveSchema } from '../../utils/Schemes'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { drivePurposes } from '../../utils/Purposes'
import { AddressAutocompleteField } from './AddressAutocompleteField'
import { Drive } from '../../model/Drive'
import { useDistance } from '../../utils/LocationHelper'
import { DetailsPanel, Header, HeaderContainer } from '../CommonStyledComponents'
import styled from 'styled-components'
import { DeleteDriveDialog } from './DeleteDriveDialog'
import { useAddressContext } from '../../utils/AddressContext'

const firstDayOfYear = (year: number) => new Date(year, 0, 1)

function minimumDate() {
	let month = new Date().getMonth()

	//If we are in january, it is allowed
	if (month === 0) return firstDayOfYear(new Date().getFullYear() - 1)

	return firstDayOfYear(new Date().getFullYear())
}

type DriveInputProps = {
	id: number
	onChange: (id: number, drive: Drive) => void
	onDelete: (id: number) => void
	expanded: boolean
	state: Drive
	isDeletable: boolean
	toggleExpanded: (id: number) => void
	onDuplicate: (drive: Drive, id: number) => void
	onSubmit: (id: number, drive: Drive, createReverse: boolean) => void
}

export function DriveInput(props: DriveInputProps) {
	const { id, onSubmit, onChange, onDelete, onDuplicate, isDeletable, state, expanded, toggleExpanded } = props

	const { values, touched, errors, handleChange, handleBlur, setFieldValue } = useFormik<Drive>({
		initialValues: state,
		validationSchema: driveSchema,
		onSubmit: console.log,
	})

	const [dialogOpen, setDialogOpen] = React.useState(false)
	const [createReverse, setCreateReverse] = React.useState(false)

	const [fetchCount, setFetchCount] = useState(0)
	const reFetch = () => setFetchCount((count) => count + 1)
	const distanceGuess = useDistance(values.fromAddress, values.toAddress, values.viaAddress, fetchCount)

	const { addAddress } = useAddressContext()

	const setExpanded = () => toggleExpanded(id)

	useEffect(() => {
		if (distanceGuess) {
			setFieldValue('distance', distanceGuess)
			setFieldValue('distanceGuess', distanceGuess)
		}
	}, [distanceGuess])

	const submit = () => {
		const { fromAddress, toAddress, viaAddress } = values

		if (fromAddress) addAddress(fromAddress)
		if (toAddress) addAddress(toAddress)
		if (viaAddress) addAddress(viaAddress)

		onSubmit(id, values, createReverse)
	}

	const duplicate = () => onDuplicate(values, id)

	const openDeleteDialog = () => setDialogOpen(true)
	const closeDeleteDialog = () => setDialogOpen(false)

	const deleteDrive = () => {
		closeDeleteDialog()
		onDelete(id)
	}

	const placeHolder = (address?: string) => {
		if (address === '' || address === undefined) return 'Adresse'
		if (address.includes(',')) return address.split(',')[0]
		return address
	}

	useEffect(() => onChange(id, { ...values, changed: true, saved: state.saved }), [values])

	const invalidAndClosed = !expanded && !driveSchema.isValidSync(values)

	return (
		<ExpansionPanel expanded={expanded}>
			<PanelSummary invalidAndClosed={invalidAndClosed} onClick={setExpanded} expandIcon={<ExpandMoreIcon />}>
				<HeaderContainer>
					<Header>{moment(values.date).format('D. MMMM')}</Header>
				</HeaderContainer>
				<HeaderContainer>
					<Header>{placeHolder(values.fromAddress)}</Header>
				</HeaderContainer>
				<HeaderContainer>
					<Header>{placeHolder(values.toAddress)}</Header>
				</HeaderContainer>
			</PanelSummary>
			<DetailsPanel>
				<Grid container spacing={3}>
					<NoPadGrid item xs={12} sm={6} id={'HI'}>
						<AddressAutocompleteField
							id="fromAddress"
							label="Fra-adresse"
							error={!!errors.fromAddress && !!touched.fromAddress}
							helperText={errors.fromAddress && touched.fromAddress ? errors.fromAddress : ' '}
							onChange={(v) => setFieldValue('fromAddress', v)}
							onBlur={(e) => {
								handleBlur(e)
								reFetch()
							}}
							value={values.fromAddress ?? ''}
							required={true}
						/>
						<AddressAutocompleteField
							id="toAddress"
							label="Til-adresse"
							error={!!errors.toAddress && !!touched.toAddress}
							helperText={errors.toAddress && touched.toAddress ? errors.toAddress : ' '}
							onChange={(v) => setFieldValue('toAddress', v)}
							onBlur={(e) => {
								handleBlur(e)
								reFetch()
							}}
							required={true}
							value={values.toAddress ?? ''}
						/>
						<AddressAutocompleteField
							id="viaAddress"
							label="Via-adresse"
							helperText={' '}
							onChange={(v) => setFieldValue('viaAddress', v)}
							onBlur={(e) => {
								handleBlur(e)
								reFetch()
							}}
							value={values.viaAddress ?? ''}
						/>
					</NoPadGrid>
					<NoPadGrid item xs={12} sm={6}>
						<KeyboardDatePicker
							margin="normal"
							id="date"
							name="date"
							label="Kørselsdato"
							value={values.date}
							onChange={(v) => setFieldValue('date', v)}
							onBlur={handleBlur}
							minDate={minimumDate()}
							maxDate={Date.now()}
							format={'DD-MM-YYYY'}
							fullWidth
						/>
						<FormHelperText> </FormHelperText>
						<FormControl fullWidth margin="normal" required>
							<InputLabel id="purpose" htmlFor="purpose">
								Formål
							</InputLabel>
							<Select
								fullWidth
								value={values.purpose}
								onChange={handleChange}
								id="purpose"
								onBlur={handleBlur}
								inputProps={{ id: 'purpose', name: 'purpose' }}
								error={!!errors.purpose && !!touched.purpose}
							>
								<MenuItem value="">
									<em>Angiv formål</em>
								</MenuItem>
								{drivePurposes.map((p) => (
									<MenuItem value={p.id}>{p.name}</MenuItem>
								))}
							</Select>
							{errors.purpose && touched.purpose ? (
								<FormHelperText error>Feltet må ikke være tomt</FormHelperText>
							) : (
								<FormHelperText> </FormHelperText>
							)}
						</FormControl>
						<TextField
							id="distance"
							label="Distance"
							margin="normal"
							required
							fullWidth
							value={values.distance}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder={`${distanceGuess ?? ''}`}
							error={!!errors.distance && !!touched.distance}
							helperText={errors.distance && touched.distance ? errors.distance : ' '}
							InputProps={{
								endAdornment: <InputAdornment position="end">Km</InputAdornment>,
							}}
						/>
					</NoPadGrid>
				</Grid>
			</DetailsPanel>
			<Divider />
			<ExpansionPanelActions>
				<PaddedFormControlLabel
					control={
						<Checkbox
							checked={createReverse}
							onChange={() => setCreateReverse(!createReverse)}
							disabled={state.saved}
							value="checkedB"
							color="primary"
						/>
					}
					id={'create-return-checkbox'}
					label="Lav automatisk retur kørsel"
				/>
				<RightAligned>
					<Button disabled={!isDeletable} size="small" onClick={openDeleteDialog}>
						Slet
					</Button>
					<DeleteDriveDialog open={dialogOpen} onClose={closeDeleteDialog} onAccepted={deleteDrive} />
					<Button size="small" disabled={!driveSchema.isValidSync(values)} onClick={duplicate}>
						Duplikér
					</Button>
					<Button
						id="save-button"
						size="small"
						color="primary"
						disabled={!driveSchema.isValidSync(values)}
						onClick={submit}
					>
						Gem
					</Button>
				</RightAligned>
			</ExpansionPanelActions>
		</ExpansionPanel>
	)
}

const PanelSummary = styled(ExpansionPanelSummary)<{ invalidAndClosed?: boolean }>`
	background-color: ${({ invalidAndClosed }) => (invalidAndClosed ? 'pink' : 'white')};
`

const NoPadGrid = styled(Grid)`
	&& {
		padding-top: 0;
		padding-bottom: 0;
	}
`
const PaddedFormControlLabel = styled(FormControlLabel)`
	margin-left: 5px;
`
const RightAligned = styled.div`
	&& {
		margin-left: auto;
	}
`
